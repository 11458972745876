import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { Color, spacing } from '@/global/style/variables';

export const FooterLinksContainer = styled.div`
  background-color: ${Color.White};
`;

export const DesktopFooter = styled.div`
  ${GridLargeWrapper}
  background-color: ${Color.White};
  padding: ${spacing.x7} 0;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${spacing.x5};

  display: none;
  ${above.sm`
    display: grid;
  `}

  ${above.md`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${spacing.x7};
  `}
`;

export const SectionContainer = styled.div``;

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: ${spacing.x15};
  margin-bottom: ${spacing.x2};

  svg {
    color: ${Color.Hallon1};
    align-self: start;
    margin-top: -1px;
  }
`;

export const MobileFooter = styled.div`
  display: block;

  ${above.sm`
    display: none;
  `}
`;
