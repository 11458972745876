import styled, { css } from 'styled-components';

import { basePaddingY } from '@/global/layouts/parts/Header/Header.styled';
import { above } from '@/global/style/breakpoint';
import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';
import { addOpacity, OpacityType } from '@/global/utils/colorUtil';

const stickyHeader = css`
  position: fixed;
  top: -100px;
  animation: fadeIn 0.2s;
  animation-fill-mode: forwards;
`;

const regularHeader = css`
  position: relative;
`;

export const HeaderMainContainer = styled.div<{ $sticky: boolean }>`
  ${GridLargeWrapper}
  ${({ $sticky }) => ($sticky ? stickyHeader : regularHeader)}

  z-index: 950;
  top: 0;
  left: 0;
  width: 100%;
  justify-self: center;
  background: ${Color.White};
  border-bottom: 1px solid ${Color.Gray2};

  @keyframes fadeIn {
    from {
      top: -100px;
    }
    to {
      top: 0;
    }
  }
`;

export const HeaderMainPlaceholder = styled.div<{ $show: boolean }>`
  height: 68px;
  background-color: ${Color.White};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`;

export const HeaderMainContent = styled.div<{ $isMinimal?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isMinimal }) => ($isMinimal ? 'auto auto' : 'min-content auto')};

  ${above.lg`
    grid-template-columns: ${({ $isMinimal }) => ($isMinimal ? 'auto auto' : 'min-content min-content auto')};

  `}
`;

const b2cLogoCss = css`
  ${above.md`
    margin-right: ${spacing.x3};
  `}
  ${above.lg`
    padding-top: ${spacing.x5};
  `}
  ${above.xlg`
    padding-top: ${spacing.x3};
  `}

  svg {
    height: 32px;

    ${above.lg`
      height: 24px;
    `}

    ${above.xlg`
      height: 40px;
    `}
  }
`;

export const LogoContainer = styled.a<{ $isB2C?: boolean }>`
  ${basePaddingY};
  margin-right: ${spacing.x2};
  color: ${Color.Hallon1};
  outline: none;
  display: flex;
  position: relative;
  align-items: center;

  ${({ $isB2C }) =>
    $isB2C
      ? b2cLogoCss
      : css`
          ${above.md`
            margin-right: ${spacing.x4};
          `}

          svg {
            height: 32px;
            ${above.md`
              height: 48px;
            `}
          }
        `}
`;

export const SvgContainer = styled.div`
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    border-radius: ${NewBorderRadius.XSmall};
    border: 2px solid ${Color.Transparent};
  }

  ${LogoContainer}:hover &:after {
    background-color: ${addOpacity(Color.Hallon1, OpacityType.Hover)};
  }

  ${LogoContainer}:active &:after {
    background-color: ${addOpacity(Color.Hallon1, OpacityType.Active)};
  }

  ${LogoContainer}:focus-visible &:after {
    border-color: ${Color.Ocean};
  }
`;

export const MainLinksNav = styled.nav`
  justify-content: start;
  align-items: flex-end;
  display: none;

  ${above.lg`
    display: grid;
    `}
`;

export const MainLinksContainer = styled.ul<{ $numberOfLinks: number }>`
  grid-template-columns: repeat(${({ $numberOfLinks }) => $numberOfLinks}, min-content);
  justify-content: start;
  align-items: flex-end;
  white-space: nowrap;
  display: grid;

  ${above.lg`
    gap: ${spacing.x1};
  `}

  ${above.xlg`
    gap: ${spacing.x3};
  `}
`;

export const MainIconLinksNav = styled.nav`
  display: flex;
  justify-content: end;
  align-items: flex-end;
`;

export const MainIconLinksContainer = styled.ul`
  display: flex;
  list-style: none;
  justify-content: end;
  align-items: flex-end;

  svg {
    height: 24px;
  }
`;

export const ActiveCartIndicator = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: ${Color.Hallon1};
  border: 1px solid ${Color.White};
  border-radius: 100%;
  top: 12px;
  right: 16px;
  z-index: 100;
`;
