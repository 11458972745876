import styled from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';

export const HamburgerIconContainer = styled.div<{ $isOpen: boolean }>`
  display: grid;
  gap: ${spacing.x05};
  position: relative;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 2px;

  span {
    position: relative;
    height: 3px;
    width: 70%;
    margin: 0 auto;
    background: ${Color.MineShaft};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    border-radius: ${BorderRadius.Small};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
    span:nth-child(1) {
      top: 8px;
      transform: rotate(-45deg);
    }
    span:nth-child(2) {
      opacity: 0;

    }

    span:nth-child(3) {
      top: -6px;
      transform: rotate(45deg);
    }

    `}
`;
