'use client';

import AppTabBar from '@/components/AppTabBar/AppTabBar';
import Footer from '@/global/layouts/parts/Footer/Footer';
import { Account, Brand, CmsSettings } from '@/types';
import { usePageBrand } from '@/app/_lib/hooks/usePageBrand';
import { usePathname } from 'next/navigation';

type AppRouterFooterProps = {
  cmsSettingsData: CmsSettings;
  account?: Account;
  isApp: boolean;
};

const hideLinksPaths = ['/checkout/kvitto', '/identify/', '/offert', '/foretag/offert'];

const AppRouterFooter = ({ account, cmsSettingsData, isApp }: AppRouterFooterProps) => {
  const pageBrand = usePageBrand(account);
  const pathname = usePathname();
  if (isApp) {
    if (!pathname?.includes('identify')) {
      return <AppTabBar />;
    }
    return null;
  }

  const showLinks = !hideLinksPaths.some((path) => pathname?.includes(path));
  const isB2B = pageBrand === Brand.B2B;

  const mainCmsData = isB2B ? cmsSettingsData.footerSectionsB2B : cmsSettingsData.footerSectionsB2C;
  const miniCmsData = isB2B ? cmsSettingsData.footerMinimalB2B[0] : cmsSettingsData.footerMinimalB2C[0];

  return <Footer mainCmsData={showLinks && mainCmsData} miniCmsData={miniCmsData!} pageBrand={pageBrand} />;
};

export default AppRouterFooter;
